<template>
  <div class="container">
    <div class="top_container">
      <a-icon @click="$router.go(-1)" class="back_btn" type="left"/>
      <span>设备报修</span>
    </div>
    <a-form-model ref="form" :model="form" :rules="rules">
      <div class="form_container">
        <a-form-model-item label="处理人" prop="handler_id">
          <a-select
            allowClear
            v-model="form.handler_id"
            style="width: 100%"
          >
            <a-select-option v-for="item in userList" :key="item.id" :value="item.id">
              {{ item.real_name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="故障描述" prop="profile">
          <a-textarea
              v-model="form.profile"
              :auto-size="{ minRows: 4, maxRows: 4 }"
          />
        </a-form-model-item>
        <extend-field-form :field-list="fieldList" :form="form"></extend-field-form>
      </div>
    </a-form-model>
    <div style="padding: 20px;text-align: center">
      <a-button type="primary" :loading="loading" :disabled="loading" @click="onSubmit">提交</a-button>
    </div>
  </div>
</template>
<script>
import ExtendFieldForm from '@/components/ExtendField/form'
import request from '@/utils/request';

export default {
  components: {ExtendFieldForm },
  data() {
    return {
      form: {},
      rules: {
        handler_id: [{required: true, message: '请选择处理人'}],
        profile: [
          {required: true, message: '请输入故障描述'},
          {max: 255, message: '长度超出限制'}
        ]
      },
      fieldList: [],
      loading: false,
      userList: [],
    }
  },
  created: function () {
    this.form.device_id = this.$route.query.device_id
    request({
      url: 'device_order/handler_list',
      data: { device_id: this.$route.query.device_id }
    }).then(r => {
      this.userList = r.data.data.user_list
      this.fieldList = r.data.data.field_list
    })
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const data = JSON.parse(JSON.stringify(this.form))
          this.loading = true
          request({
            url: 'device_order/add_order',
            data: data
          }).then(r => {
            if (r.data.success) {
              alert('报修成功')
              this.$router.replace({ path: '/doh', query: {
                  device_id: this.$route.query.device_id,
                  qr_id: this.$route.query.qr_id
              }})
            } else {
              alert('网络异常,请稍后重试')
            }
          }).finally(() => {
            this.loading = false
          })
        }
      });
    }
  }
}
</script>
<style lang="less" scoped>
@import "../assets/css/form.less";

.ant-form-item {
  align-items: center;
}

/deep/ .ant-form-item-label {
  label::before {
    display: inline-block;
    margin-right: 11px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '';
  }
  .ant-form-item-required::before {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}

.container {
  padding: 8px;
  padding-top: 58px;

  .form_container {
    background-color: #fff;
    border-radius: 10px;
    .ant-form-item:last-child {
      border-bottom: none;
    }
  }

  .mt10 {
    margin-top: 10px;
  }

  .button-view {
    padding: 0 8px;
    display: flex;
    justify-content: flex-end;
  }

  .form-button {
    display: flex;
    align-items: center;
    margin-left: 5px;
    background: #3573e0;
    color: #fff;
    border-radius: 3px;
    padding: 2px 5px;
    font-size: 14px;
  }
}
</style>
